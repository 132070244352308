import React from 'react';


const Loading = () => {
  //
  return (
    <h1>Loading...</h1>
  );
};

export default Loading;
