import React from 'react';


const NotFoundPage = () => {
  //
  return (
    <>
      <div id="wrapper">
        <nav id="nav">
          <span>
              Not Found
          </span>
        </nav>
      </div>
    </>
  );
};

export default NotFoundPage;
