import React from 'react';


const Footer = () => {
  //
  return (
    <ul className="copyright">
      <li>&copy; Untitled.</li>
      <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
    </ul>
  );
};

export default Footer;
